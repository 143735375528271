import React from 'react'
import { graphql, Link } from 'gatsby'
import { Styled } from 'theme-ui'

import Layout from '../../components/layout'
import Logo from '../../components/Logo'
import Container from '../../components/Container'

export default ({ data }) => (
  <Layout>
    <Link style={{ padding: 16, display: 'block' }} to="/">
      <Logo />
    </Link>
    <Container>
      <Styled.h1>Type scales</Styled.h1>
      <Styled.p>
        Scales for typography are used in order to ensure consistency and
        harmony. They are typically based on a ratio and number of steps that
        are used to compute the font sizes.
      </Styled.p>
      <Styled.p>
        How exactly you come up with a scale doesn't really matter. What's
        important is that you use one.
      </Styled.p>
      <Styled.p mb={4}>
        Below is a curated list of some of the most popular type scales.
      </Styled.p>
      <Styled.hr />
      {data.typeScales.nodes.map(scale => {
        return (
          <h3 key={scale.name}>
            <Link
              to={`/explore/type-scales/${scale.name}`}
              css={{
                color: 'black'
              }}
            >
              {scale.name.replace(/\-/g, ' ')}
            </Link>
            <span css={{ paddingLeft: 8, fontSize: 14 }}>{scale.value}</span>
          </h3>
        )
      })}
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query TypeScales {
    typeScales: allTypeScalesJson {
      nodes {
        name
        value
      }
    }
  }
`
